.main{
    .container{
        width: 1440px;
        height: 100vh;
        margin: 0 auto;
        display: flex;

    align-items: center;

    }
}



.img-container{
    width: 1440px;
    height: 960px;
    position: relative;
    overflow: hidden;
    &:after{
        content: '';
        position: absolute;
        width: 100%;
        background: white;
        height: 100%;
        top: 0;
        right: 0;
    }
    img{
        width: 1440px;
        position: absolute;
    }
}



.section_test{
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    background-color: aqua;
    .container_test{
        margin-top: 100vh;
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;

    }
}